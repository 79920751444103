import React from 'react';
import PropTypes from "prop-types";
import ReactDOM from 'react-dom';
import { Modal, Button, Input,Icon,message } from 'antd';
import { Base64 } from "js-base64";
import DotsVerticalIcon from "mdi-react/ShareIcon";

export default class ShareMeeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title:"",
            oameetingurl:"",
            meetingurl:"",
            visible: false
         };
    }
    showModal = () => {
        this.setState({
            visible: true
        });

        let loginInfo = this.props.loginInfo;
        let title = "音视频会议号："+loginInfo.roomId;
        let urlparams = {roomid:loginInfo.roomId,mname:loginInfo.mname,m:loginInfo.mike,c:loginInfo.camera};
        let urlencode = encodeURIComponent(Base64.encode(JSON.stringify(urlparams)));
        let oameetingurl = "https://oa.seschip.com/MeetingDetail?RoomId=" + loginInfo.roomId;
        let meetingurl = window.location.protocol + "//" + window.location.host + "/?url=" + urlencode;
        this.setState({ title,oameetingurl,meetingurl });
    }
    handleOk = (e) => {
        let loginInfo = this.props.loginInfo;
        const {title,oameetingurl,meetingurl} = this.state;
        let CopyContent = loginInfo.displayName+" 邀请您加入【"+loginInfo.mname+"】音视频会议室<br/>点击以下链接可直接加入会议：<br/>内部用户："+oameetingurl+"<br/>外部用户："+meetingurl+"<br/><br/>"+"#"+title+"<br/><br/>复制该信息，打开支持浏览器即可参加会议！";
        this.onCopyCot(CopyContent);
        this.setState({
            visible: false,
        });
    }
    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }

    onFocus = (e) => {
        ReactDOM.findDOMNode(e.target).select();
    }

    onCopyToOAMeetingUrl = ()=>{
        const {oameetingurl} = this.state;
        this.onCopyCot(oameetingurl);
    }

    onCopyToMeetingUrl = ()=>{
        const {meetingurl} = this.state;
        this.onCopyCot(meetingurl);
    }

    onCopyCot = (cot) => {
        const pEle = document.createElement('p');
        pEle.innerHTML = cot || '';
        document.body.appendChild(pEle);

        const range = document.createRange(); // 创造range
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
        range.selectNode(pEle); // 选中需要复制的节点
        window.getSelection().addRange(range); // 执行选中元素

        const copyStatus = document.execCommand("Copy"); // 执行copy操作
        if(copyStatus)
            message.success("邀请链接复制成功！");
        else
            message.error("邀请链接复制失败！");
        document.body.removeChild(pEle);
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
    }

    render() {
        return (
            <div className="app-header-tool-container">
                <Button ghost size="large" type="link" onClick={this.showModal}>
                  <Icon
                    component={DotsVerticalIcon}
                    style={{ display: "flex", justifyContent: "center" }}
                  />
                  邀请
                </Button>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText='复制全部'
                    cancelText='取消'>
                    <div>
                        <div>
                            <span>OA系统视频会议链接发送给您的同事</span><Button size="large" type="link" onClick={this.onCopyToOAMeetingUrl}>复制</Button>
                            <Input onFocus={this.onFocus} readOnly={true} value={this.state.oameetingurl}  />
                        </div>
                        <br />
                        <div>
                            <span>视频会议连接链接发送给您的朋友</span><Button size="large" type="link" onClick={this.onCopyToMeetingUrl}>复制</Button>
                            <Input onFocus={this.onFocus} readOnly={true} value={this.state.meetingurl}  />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

ShareMeeting.propTypes = {
    roomInfo: PropTypes.any,
}