import React from "react";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import VideocamOffIcon from "mdi-react/VideocamOffIcon";
import { Avatar } from 'antd';

class SmallVideoComponent extends React.Component{
    constructor(){
        super();
        this.state = {
            strPlayer:null
        }
    }

    componentDidMount = () => {
        let that = this;
        const {hosturl,roomId,id} = that.props;
        var url = hosturl+"live/livestream/" + roomId + '/' + id;
        this.ReMeetingPlay(url);
        console.log("Small_ReMeetingPlay.ReMeetingPlay==>>",that.video);
        //if(stream)
        //that.video.srcObject = stream;
    }

    ReMeetingPlay = (url) => {
        let that = this;
        let {strPlayer} = that.state;
        if(strPlayer)
            strPlayer.close();

        let video = that.video;
        if(video && video.srcObject)
            video.srcObject = null;
        that.setState({strPlayer:new SrsRtcPlayerAsync()},()=>{
            let {strPlayer} = that.state;
            strPlayer.play(url).then(function(session){
                video.srcObject = strPlayer.stream;
                console.log("Small_ReMeetingPlay.ReMeetingPlay.session==>",session);
            }).catch(function (reason) {
                console.error(reason);
                strPlayer.close();
                setTimeout(function () {that.ReMeetingPlay(url);} , 1500);
            });
        });
    }
    
    componentWillUnmount = () => {
        let {strPlayer} = this.state;
        if(strPlayer)
            strPlayer.close();
        if(this.video && this.video.srcObject)
            this.video.srcObject = null;
    }

    handleClick = () => {
        let {id} = this.props;
        console.log("handleClick",id);
        this.props.onClick({id});
    }

    render = () => {
        const { id,name,avatar,audioMuted,videoMuted } = this.props;
        return (
            <div onClick={this.handleClick} className="small-video-div">
                <video
                    ref={ref =>{
                        this.video = ref;
                    }}
                    id={id+"-video"}
                    autoPlay
                    playsInline
                    className="small-video-size"
                />
                {
                    videoMuted ? "" : 
                    <div className='small-video-avatar'>
                        {avatar ? (<Avatar size={40} src={avatar} />) : (<Avatar size={40} icon="user"/>)}
                    </div>
                }
                <div className='small-video-id-div'>
                    {audioMuted ? "" : <MicrophoneOffIcon size={18} color="white"/>}
                    {videoMuted ? "" : <VideocamOffIcon size={18} color="white"/>}
                    <a className='small-video-id-a'>{name}</a>
                </div>
            </div>
        )
    }
}

export default SmallVideoComponent;