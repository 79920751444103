import React from "react";
import PropTypes from 'prop-types';
import { Avatar } from "antd";


export default class UserInfo extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                <div className='rowLayout userItem' key={this.props.user.UserId}>
                    {this.props.user.UserAvatar?(<Avatar size="small" src={this.props.user.UserAvatar} />):(<Avatar size="small" icon="user"/>)}
                    <span className="lblUserName">
                        {this.props.user.UserName}
                    </span>
                </div>
            </div>
        );
    }
}

UserInfo.propTypes = {
    user: PropTypes.any.isRequired,
}