import React, { Component } from "react";
import { Avatar } from "antd";
import { Icon } from "antd";
import UserIcon from "mdi-react/UserIcon";

export default class ChatBubble extends Component {
    constructor(props) {
        super();
        this.state = { message: props.message };
    }
    render() {
        //Other
        if (this.props.message.id == 1) {
            return (
                <div className='bubble-left'>
                    <div className='bubble-head'>
                        {this.props.message.senderAvatar?(<Avatar size="small" src={this.props.message.senderAvatar} />):(<Avatar size="small" icon="user"/>)}
                        {/* <Icon component={UserIcon} /> */}
                    </div>
                    <div className='bubble-msg'>
                        <p className="sender-name">{this.props.message.senderName}[{this.props.message.timesTamp}]</p>
                        <div className='bubble-msgword'>
                            <p className='pl'>
                            {this.props.message.message}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
        //Self message
        else if (this.props.message.id == 0) {
            return (
                <div className='bubble-right'>
                    <div className='bubble-msg'>
                        <p style={{ textAlign: 'right' }} className="sender-name">{this.props.message.senderName}[{this.props.message.timesTamp}]</p>
                        <div className='bubble-msgword'>
                            <p className='pr'>
                                {this.props.message.message}
                            </p>
                        </div>
                    </div>

                    <div className='bubble-head'>
                        {this.props.message.senderAvatar?(<Avatar size="small" src={this.props.message.senderAvatar} />):(<Avatar size="small" icon="user"/>)}
                        {/* <Icon component={UserIcon} /> */}
                    </div>

                </div>
            )
        }
        //System message
        else if (this.props.message.id == 2) {
            return (
                <div className='bubble-middle'>
                    <div className='bubble-msg'>
                        <div className='bubble-msgword-middle'>
                            <p className='pm'>
                                {this.props.message.message}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
    

