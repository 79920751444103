import React from "react";
import { Spin, notification } from "antd";
import "../styles/css/meeting.scss";
import { LocalVideoComponent, BigVideoComponent, SmallVideoComponent } from "./video";
//import { LocalStream } from "ion-sdk-js";
//import { Scrollbars } from 'react-custom-scrollbars';

class MeetingPage extends React.Component {
    constructor() {
        super()
        this.state = {
            localStream: null,
            localScreen: null,
            publisher: null
        };
    }

    cleanUp = async () => {
        let that = this;
        let { localStream,localScreen,publisher } = that.state;
        if(localScreen){
            localScreen.getTracks().forEach((item, index) => {
              item.stop();
            });
            that.setState({localScreen:null});
        }
        
        if (publisher)
            publisher.close();
        await that.setState({ localStream: null, localScreen: null });
        await that.unpublish(localStream);
    }

    unpublish = async stream => {
        if (stream) {
            await this.stopMediaStream(stream);
            //await stream.unpublish();
        }
    }

    stopMediaStream = async (stream) => {
        let tracks = stream.getTracks();
        for (let i = 0, len = tracks.length; i < len; i++) {
            await tracks[i]?.stop();
        }
    }

    muteMediaTrack = (type,enabled,callback) => {
        let { localStream } = this.state;
        localStream?.getTracks().forEach(track => {
            if(track.kind === type){
                track.enabled = enabled;
                console.log("enabled.track==>>", track);
            }
        });
        if(callback)
            callback(localStream?.id);
    }

    publishLocalStream = (callback) => {
        let that = this;
        const { loginInfo,publisherUrl,settings } = that.props;
        let { localStream,localScreen,publisher } = that.state;
        //let streams = this.state.streams;
        // client.ontrack = (track, stream) => {
        //     console.log("go track", track, "for stream", stream.id);
        //     track.onunmute = () => {
        //         console.log("onunmute:::" + stream.id);
        //         if (track.kind === "video") {
        //             if (!this.hasStream(stream)) {
        //                 streams.push({ id: stream.id, stream: stream, name: "参会者" });
        //                 this.setState({ streams });
        //                 stream.onremovetrack = () => {
        //                     console.log("onremovetrack::" + stream.id);
        //                     streams = streams.filter(item => item.id !== stream.id);
        //                     this.setState({
        //                         streams: streams
        //                     });
        //                 }
        //             }
        //         }
        //         this.updateMuteStatus(stream, false);
        //     };

        //     track.onmute = () => {
        //         console.log("onmute:::" + stream.id);
        //         this.updateMuteStatus(stream, true);
        //     }
        // }

        // LocalStream.getUserMedia({
        //     codec: settings.codec.toUpperCase(),
        //     resolution: settings.resolution,
        //     bandwidth: settings.bandwidth,
        //     audio: {echoCancellation: true,noiseSuppressionEnabled: true,sampleRate:44100},
        //     video: {width:{ideal:320,max:576}},
        // }).then((stream) => {
        //     localStream = stream;
		// 	client.publish(stream);
        //     this.setState({ localStream });
        //     if(callback)
        //         callback(localStream.id);
        // }).catch((e) => {
        //     console.log("handleLocalStream error => " + e);
        //     if(callback)
        //         callback(null);
        // });

        console.log("publishLocalStream==>>",settings);
        var url = publisherUrl+"live/livestream/" + loginInfo.roomId + '/' + (loginInfo.userid?loginInfo.userid: loginInfo.uid);
        const constraints = {
            codec: settings?settings.codec.toUpperCase():"VP8",
            resolution: settings?settings.resolution:"shd",
            bandwidth: settings?settings.bandwidth:"512",
            audio: {echoCancellation: true,noiseSuppressionEnabled: true,autoGainControl:true,sampleRate:44100},
            video: {width:640, height:480, frameRate:15},
        };

        if(localScreen){
            localScreen.getTracks().forEach((item, index) => {
              item.stop();
            });
            localScreen = null;
            that.setState({localScreen});
        }
        
        if (publisher)
            publisher.close();

        that.setState({publisher:new SrsRtcPublisherAsync()},()=>{
            let { publisher } = that.state;
            return publisher.publish_media(url,constraints).then(function(res){
                localStream = res.stream;
                that.setState({ localStream });
                if(callback)
                    callback(localStream?.id);
            }).catch(function (reason) {
                console.error(reason);
                publisher.close();
                setTimeout(()=>{that.publishLocalStream(callback);},1500);
            });
        });
    }

    handleScreenSharing = async (callback) => {
        let that = this;
        let { localStream,localScreen,publisher } = that.state;
        const { loginInfo,publisherUrl,settings } = that.props;

        var url = publisherUrl+"live/livestream/" + loginInfo.roomId + '/' + (loginInfo.userid?loginInfo.userid: loginInfo.uid);
        const constraints = {
            codec: settings.codec.toUpperCase(),
            resolution: settings.resolution,
            bandwidth: settings.bandwidth,
            audio: {echoCancellation: true,noiseSuppressionEnabled: true,sampleRate:44100},
            video: true//{width:{ideal:320,max:576}}
        };
        
        if (publisher)
            publisher.close();

        that.setState({publisher:new SrsRtcPublisherAsync()},()=>{
            let { publisher } = that.state;
            return publisher.publish_displaymedia(url,constraints,localStream,function(res){
				console.log("handleScreenSharing.publish_displaymedia==>>",res);
				that.setState({ localScreen:null },()=>{
					if(callback)
						callback({stream:null,handletype:"screen",handlestate:"success"});
				});
            }).then(function(res){
                that.setState({ localScreen:res.stream },()=>{
					let { localScreen } = that.state;
					if(callback)
					    callback({stream:localScreen.id,handletype:"screen",handlestate:"success"});
				});
            }).catch(function (reason) {
                console.error(reason);
                publisher.close();
				that.setState({ localScreen:null },()=>{
					if(callback)
					    callback({stream:null,handletype:"screen",handlestate:"exception"});
				});
            });
        });
    }

    // startStreamPlay = (userid)=>{
    //     let that = this;
    //     let { streamlist } = that.state;
    //     let { publisherUrl,loginInfo } = that.props;
    //     streamlist = streamlist.filter(item=>item.userid !== userid);
    //     var url = publisherUrl + loginInfo.roomId + '/' + userid;
    //     let player = new SrsRtcPlayerAsync();
    //     streamlist.push({userid: userid, url: url, stream: player.stream});
    //     that.setState({ streamlist: streamlist},() => {
    //         player.play(url).then((session) => {
    //             console.log("startStreamPlay.Started==>>",url,session); 
    //         }).catch(function (reason) {
    //             console.error(reason);
    //             player.close();
    //             setTimeout(function () {that.startStreamPlay(userid);} , 1500);
    //         });
    //     });
    //     console.log("startStreamPlay==>>",streamlist);
    // }

    // stopStreamPlay = (userid) => {
    //     let { streamlist } = this.state;
    //     streamlist = streamlist.filter(item=>item.userid !== userid);
    //     console.log("stopStreamPlay==>>",streamlist);
    //     this.setState({ streamlist: streamlist});
    // }

    notification = (message, description) => {
        notification.info({
            message: message,
            description: description,
            placement: "bottomRight"
        });
    };

    render = () => {
        const { localStream } = this.state;
        const {users,loginInfo,localVideoEnabled,localAudioEnabled,publisherUrl,vidFit,bigStreamUserId,ChangeVideoPosition,HandleClick,Collapsed} = this.props;
        return (
            <div className="meeting-layout">
                {users?.filter(item => item.UserId !== (loginInfo.userid?loginInfo.userid: loginInfo.uid)).length === 0 && (
                    <div className="meeting-layout-wating" onClick={HandleClick}>
                        <Spin size="large" tip="等待其他参会者加入会议..." />
                    </div>
                )
                }
                {users.filter(it => it.UserId !== (loginInfo.userid?loginInfo.userid: loginInfo.uid)).map((item, index) => {
                    return bigStreamUserId? (
                        item.UserId == bigStreamUserId?(
                            <BigVideoComponent
                                id={item.UserId}
                                key={index}
                                name={item.UserName}
                                hosturl={publisherUrl}
                                roomId={loginInfo.roomId}
                                avatar={item.UserAvatar}
                                audioMuted={item.AudioEnabled}
                                videoMuted={item.VideoEnabled}
                                vidFit={vidFit}
                                HandleClick={HandleClick}
                            />
                        ) : (
                            ""
                        )
                    ) : (
                        index == 0 ? (
                        <BigVideoComponent
                            id={item.UserId}
                            key={index}
                            name={item.UserName}
                            hosturl={publisherUrl}
                            roomId={loginInfo.roomId}
                            avatar={item.UserAvatar}
                            audioMuted={item.AudioEnabled}
                            videoMuted={item.VideoEnabled}
                            vidFit={vidFit}
                            HandleClick={HandleClick}
                        />
                        ) : (
                            ""
                        )
                    )
                })
                }

                {localStream && (
                    <div className="meeting-local-video-layout" style={{display:Collapsed?"":"none"}}>
                        <LocalVideoComponent
                            id={loginInfo?loginInfo.uid+'-video': localStream.id + "-video"}
                            stream={localStream}
                            name={'我自己-'+loginInfo.displayName}
                            avatar={loginInfo.userAvatar}
                            audioMuted={localAudioEnabled}
                            videoMuted={localVideoEnabled}
                        />
                    </div>
                )
                }
                
                <div className="small-video-list-div" style={{display:Collapsed?"":"none"}}>
                    <div className="small-video-list">
                        {
                            users.filter(it => it.UserId !== (loginInfo.userid?loginInfo.userid: loginInfo.uid)).map((item, index) => {
                                return bigStreamUserId?(
                                    item.UserId != bigStreamUserId ? (
                                        <SmallVideoComponent
                                                id={item.UserId}
                                                key={index}
                                                name={item.UserName}
                                                hosturl={publisherUrl}
                                                roomId={loginInfo.roomId}
                                                avatar={item.UserAvatar}
                                                audioMuted={item.AudioEnabled}
                                                videoMuted={item.VideoEnabled}
                                                onClick={ChangeVideoPosition}
                                            />
                                    ) : (
                                        ""
                                    )
                                ):(
                                    index > 0 ? (
                                        <SmallVideoComponent
                                            id={item.UserId}
                                            key={index}
                                            name={item.UserName}
                                            hosturl={publisherUrl}
                                            roomId={loginInfo.roomId}
                                            avatar={item.UserAvatar}
                                            audioMuted={item.AudioEnabled}
                                            videoMuted={item.VideoEnabled}
                                            onClick={ChangeVideoPosition}
                                        />
                                    ) : (
                                        ""
                                    )
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default MeetingPage;
