'use strict'
import React, {Component} from "react";
import PropTypes from 'prop-types';
import UserInfo from "./userinfo";
import "../../styles/css/user.scss";

export default class UserList extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="userListContainer">
                <div className='title-panel'>
                    <span className='title-user'>参会用户-【{this.props.users.length}】人</span>
                </div>
                <div id="userPanel" className='userPanel'>
                    {
                        this.props.users.map((user,index)=>{
                            return(
                                <UserInfo key={user.UserId} user={user}/>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

UserList.propTypes = {
    users: PropTypes.array.isRequired,
}

