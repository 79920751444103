import React from "react";
import { Form, Icon, Input, Button, Card } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { Base64 } from "js-base64";
import "../styles/css/login.scss";


class LoginPage extends React.Component {
  constructor() {
    super()
    this.state = {
      roomId: '',//会议房间号
      mname: '',//会议名称
      userid: '',//参会者Id
      displayName: '',//参会者昵称
      mike: false,//麦克风
      camera: false//摄像头
    };
  }
  componentDidMount = () => {
    let that = this;
    const { form } = that.props;
    let params = that.getRequest();
    let localStorage = reactLocalStorage.getObject("loginInfo");
    console.log("login.componentDidmount()==>>",localStorage,params);
    if (params && params.hasOwnProperty('url')) {
      let url = JSON.parse(Base64.decode(decodeURIComponent(params.url)));
      that.setState({roomId:url.roomid?url.roomid:localStorage?localStorage.roomId:'',
      mname:url.mname?url.mname:'',
      userid:url.userid?url.userid.toString():'',
      displayName:url.username?url.username:localStorage?localStorage.displayName:'',
      mike:url.m,
      camera:url.c
    },()=>{
      if(url.roomid && url.username && url.mname)
      {
        const handleLogin = that.props.handleLogin;
        const {roomId,mname,userid,displayName,mike,camera} = that.state;
        handleLogin({roomId, mname, userid, displayName,mike,camera});
      }else{
        const {roomId,displayName} = that.state;
        form.setFieldsValue({
          'roomId': roomId,
          'displayName': displayName
        });
      }
      });
    }else{
      if (localStorage) {
        that.setState({roomId:localStorage.roomId,displayName:localStorage.displayName},() => {
          form.setFieldsValue({
            'roomId': that.state.roomId,
            'displayName': that.state.displayName
          });
        });
      }
    }
  }

  componentWillUnmount = () => {
    this.cleanup();
  }

  cleanup = async () => {
  }

  handleSubmit = e => {
    e.preventDefault();
    let that = this;
    const { form } = that.props;

    form.validateFields((err, values) => {
      if (!err) {
        const handleLogin = that.props.handleLogin;
        that.setState({roomId: values.roomId,displayName: values.displayName},()=>{
          const {roomId,mname,userid,displayName,mike,camera} = that.state;
          handleLogin({roomId, mname, userid, displayName,mike,camera});
        });
      }
    });
  };

  getRequest() {
    let url = location.search;
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
      let str = url.substr(1);
      let strs = str.split("&");
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-bg">
        <Form onSubmit={this.handleSubmit} className="login-form" style={{ padding: '20px' }}>
        <h1 className="login-title">知码芯-音视频会议系统</h1>
          <Form.Item>
            {getFieldDecorator("roomId", {
              rules: [{ required: true, message: "请输入会议Id" }]
            })(
              <Input
                readOnly={true}
                prefix={<Icon type="team" className="login-input-icon" />}
                placeholder="会议Id"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("displayName", {
              rules: [{ required: true, message: "请输入用户名" }]
            })(
              <Input
                prefix={
                  <Icon type="contacts" className="login-input-icon" />
                }
                placeholder="用户名"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-join-button">
              进入会议
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrappedLoginForm = Form.create({ name: "login" })(LoginPage);
export default WrappedLoginForm;
