'use strict'
import React, {Component} from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import "../../styles/css/chat.scss";
import ChatInput from "./chat_input";
import ChatBubble from "./chat_bubble";

export default class ChatFeed extends Component{
    constructor(props){
        super(props)
        this.state = {
            messages:props.messages || [],
        }
    }

    _scrollToBottom = () => {
        const { chat } =  this.refs;
        if(chat != undefined){
            const scrollHeight = chat.scrollHeight;
            const height = chat.clientHeight;
            const maxScrollTop = scrollHeight - height;
            ReactDOM.findDOMNode(chat).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            
        }
    }

    _renderMessages = (messages) =>{
        var message_nodes = messages.map((curr,index)=>{
            return (
                <ChatBubble
                    key={Math.random().toString(36)}
                    message={curr}
                />
            )
        })
        return (
            <div key={Math.random().toString(36)} className='chatbubble-wrapper'>
                {message_nodes}
            </div>
        )
    }


    render(){

        window.setTimeout(()=>{
            this._scrollToBottom()
        },10)
        
        return (
            <div id="chat-panel" className='chat-panel'>
                <div className='title-panel'>
                    <span className='title-chat'>会议群聊</span>
                </div>
                <div ref='chat' className='chat-history'>
                    <div>
                        {this._renderMessages(this.props.messages)}
                    </div>   
                </div>
                <ChatInput onSendMessage={this.props.onSendMessage}/>
            </div>
        )
    }
}

ChatFeed.propTypes = {
    isTyping:PropTypes.bool,
    messages: PropTypes.array.isRequired,
    onSendMessage: PropTypes.func.isRequired,
}