import React from "react";
import LoginForm from "./login_page";
import { Layout, Spin, Modal, notification, Button, Icon } from "antd";
const { confirm,warning } = Modal;
const { Content, Sider } = Layout;
import MeetingPage from "./meeting_page";
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import VideoIcon from "mdi-react/VideoIcon";
import VideoOffIcon from "mdi-react/VideoOffIcon";
import TelevisionIcon from "mdi-react/TelevisionIcon";
import TelevisionOffIcon from "mdi-react/TelevisionOffIcon";
import HangupIcon from "mdi-react/PhoneHangupIcon";
import FullscreenIcon from "mdi-react/FullscreenIcon";
import FullscreenExitIcon from "mdi-react/FullscreenExitIcon";
import CheckboxIntermediateIcon from "mdi-react/CheckboxIntermediateIcon";
import CheckboxBlankOutlineIcon from "mdi-react/CheckboxBlankOutlineIcon";
import ShareMeeting from "./share_meeting";
import MediaSettings from "./setting";
import { v4 as uuidv4 } from 'uuid';
//import { Client } from 'ion-sdk-js';
//import { IonSFUGRPCWebSignal } from 'ion-sdk-js/lib/signal/grpc-web-impl';
//import { IonSFUJSONRPCSignal } from 'ion-sdk-js/lib/signal/json-rpc-impl';
//import {SrsRtcPublisherAsync,SrsRtcPlayerAsync} from '../scripts/srs.sdk';
import { reactLocalStorage } from "reactjs-localstorage";
import UserList from "./user";
import ChatFeed from "./chat";
import Message from "./chat/message";
import "../styles/css/app.scss";
import wx from 'weixin-js-sdk';
//import  VConsole  from  'vconsole';
import watermark from 'watermark-dom';

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            login: false,
            loading: false,
            collapsed: true,
            bottomtools:false,
            retryCount:0,
            autoreconn:true,
            socket:undefined,
            loginInfo: {
                uid:'',
                roomId:'',
                userid:'',
                displayName:'',
                userAvatar:'',
                mike:'',
                camera:''
            },
            users: [],
            messages: [],
            publisherUrl: 'webrtc://webrtc.seschip.com/',
            localAudioEnabled: true,
            localVideoEnabled: false,
            screenSharingEnabled: false,
            vidFit: true,
            isFullScreen: false,
            isMobileTerminal: false,
            docElm:undefined,
            bigStreamUserId:'',
			StreamMode:'',
            //vConsole:undefined
        };

        this.settings = {
            selectedAudioDevice: "",
            selectedVideoDevice: "",
            resolution: "hd",
            bandwidth: 1024,
            codec: "vp8",
        };

        let settings = reactLocalStorage.getObject("settings");
        if (settings.codec !== undefined) {
            this.settings = settings;
        }
    }
	
	componentDidMount = () => {
		let that = this;
		that.initnotify();
	}
	
	/**
	 * 初始化消息通知
	 */
	initnotify=async()=>{
		// 先检查浏览器是否支持
		if (!("Notification" in window)){
		  console.warn("此浏览器不支持桌面通知！");
		  return;
		}
		var PERMISSON_GRANTED = "granted";//用户已明确的授予了显示通知权限
		var PERMISSON_DEFAULT = "default";//用户还未被询问是否授权，这种情况下权限将视为denied
		if(Notification.permission === PERMISSON_DEFAULT){
			Notification.requestPermission().then(res=> {
				console.log("res=>",res);
				if(res !== PERMISSON_GRANTED){
					if(res === PERMISSON_DEFAULT){
						console.warn("用户关闭授权，未刷新新面之前可以再次请求授权")
					}else{
						console.log('用户拒绝授权 不能显示通知');
					}
				}
			});
		}
	}
	
	/**
	 * 浏览器通知
	 */
	notify=async(title,message,url)=>{
		// 先检查浏览器是否支持
		if (!("Notification" in window)){
		  console.warn("此浏览器不支持桌面通知！");
		  return;
		}
		var PERMISSON_GRANTED = "granted";//用户已明确的授予了显示通知权限
		var PERMISSON_DENIED = "denied";//用户已明确的拒绝了显示通知的权限
		var PERMISSON_DEFAULT = "default";//用户还未被询问是否授权，这种情况下权限将视为denied
		const options={
		  body:message,//内容主体
		  silent:true,//是否需要声音
		  lang:'zh-CN',//语言
		  sticky:false,//是否粘滞性，即不容易被用户清理。非必须，默认false表示不具粘滞性
		  tag:new Date().getTime(),//通知标识
		  renotify:true,//通知是否使用叠加效果，默认true，false则替换
		  noscreen:false,//是否不在屏幕上显示通知，用于移动端，默认false
		  requireInteraction:false,//通知不自动关闭，默认为false(自动关闭)
		  data:url,//任意类型，通知相关联的数据
		  icon:'https://meeting.seschip.com/styles/image/favicon.ico',//通知图标
		};
		
		console.log("permission=>",Notification.permission);
		if(Notification.permission === PERMISSON_GRANTED){
		  var notice = new Notification(title, options);
		  notice.onshow=function(e){
		    console.log("show: ",e);
		  };
		  notice.onclick=function(e){
		    console.log("click: ",e);
		    window.focus();
		    notice.close();
		  };
		  notice.onclose=function(e){
		    console.log("close: ",e);
		  };
		  notice.onerror=function(e){
		    console.log("error: ",e);
		  };
		}else if(Notification.permission === PERMISSON_DEFAULT){
		  Notification.requestPermission().then(res=> {
		    console.log("res=>",res);
		  	if(res === PERMISSON_GRANTED){
		      var notice = new Notification(title, options);
		      notice.onshow=function(e){
		        console.log("show: ",e);
		      };
		      notice.onclick=function(e){
		        console.log("click: ",e);
		        window.focus();
		        notice.close();
		      };
		      notice.onclose=function(e){
		        console.log("close: ",e);
		      };
		      notice.onerror=function(e){
		        console.log("error: ",e);
		      };
		    }else if(res === PERMISSON_DEFAULT){
		      console.warn("用户关闭授权，未刷新新面之前可以再次请求授权")
		    }else{
		      console.log('用户拒绝授权 不能显示通知');
		    }
		  });
		}else{
		  console.log('用户曾经拒绝显示通知');
		}
	}

    handleLogin = async (values) => {
        console.log("handleLogin==>>",values);
        this.setState({
            login: true,
            loading: false,
            loginInfo: {
                uid: uuidv4().toString().split('-')[0],
                roomId: values.roomId,
                mname:values.mname,
                userid: values.userid,
                displayName: values.displayName,
                mike: values.mike,
                camera: values.camera
            },
			localAudioEnabled:values.mike,
			localVideoEnabled:values.camera,
            isMobileTerminal:this.isMobileTerminal(),
            docElm:document.documentElement,
            //vConsole:new VConsole()
        },()=>{
            let { loginInfo } = this.state;
            reactLocalStorage.remove("loginInfo");
            reactLocalStorage.setObject("loginInfo", loginInfo);
            document.title = "知码芯-音视频会议系统-"+loginInfo.mname;
            this.onReWsConnect();
            // const signal = new IonSFUJSONRPCSignal("wss://meeting.seschip.com/jsonrpc_sfu");//IonSFUGRPCWebSignal("wss://localhost:8100/ws"); //
            // this.IonClient = new Client(signal);
            // console.log('onclose::IonClient==>>' + this.IonClient);
            // signal.onopen = () => {
            //     console.log("SFU流媒体服务器连接成功....");
            //     this.IonClient.join(this.state.loginInfo.roomId, this.state.loginInfo.displayName);
            //     this.onJoin(values);
            // }
        });
    };

    onReWsConnect = async () => {
        let that = this;
        let { socket,retryCount,loginInfo,localAudioEnabled,localVideoEnabled,publisherUrl } = that.state;
        if(socket != null && socket.readyState == WebSocket.OPEN){
            console.warn("WebSocket connection established！");
            return;
        }

        socket = new WebSocket("wss://api.seschip.com/msn/MeetingServer");//"ws://"+window.location.hostname+":8000/ws"
        that.setState({socket});

        socket.onopen = () => {
            retryCount = 0;
            that.setState({retryCount});
            console.log("信令服务器连接成功....");
            let message = {
                'MsgDataType': 0,//加入房间
                'MsgSendType':1,//会议消息
                'SenderId':loginInfo.userid?loginInfo.userid:loginInfo.uid,//加入房间者Id
                'SenderName':loginInfo.displayName,//加入房间者名称
                'MessageData': {
                    RoomId: loginInfo.roomId,
                    AudioEnabled:localAudioEnabled,
                    VideoEnabled:localVideoEnabled
                }
            };
            that.send(message);
        };
        
        socket.onmessage = (e) => {
            var message = JSON.parse(e.data);
            console.log("OnMessage==>>", message);
            if(message.MsgSendType===1){
                switch (message.MsgDataType) {
                    case 0://进入会议
                        if(message.MessageData.IsExist){
                            that.cleanUp();
                            that.setState({autoreconn:false}, () => {
                                warning({
                                    title:"温馨提示",
                                    content:message.MessageData.Data,
                                    mask:true,
                                    centered:true,
                                    okText:"知道了",
                                    onOk(){
                                        if((/MicroMessenger/i).test(window.navigator.userAgent.toLowerCase())) {
                                            wx.closeWindow();
                                        }else{
                                            window.opener = null;
                                            window.open("about:blank", "_top");
                                            window.close();
                                        }
                                        that.setState({ login: false });
                                    }
                                })
                            });
                        }else{
                            if ((loginInfo.userid?loginInfo.userid: loginInfo.uid) === message.SenderId) {
								that.onPublishLocalStream();
								loginInfo.userAvatar = message.SenderAvatar;
                            }else
								that.showMessage(message.SenderName + '进入会议');
							
							let userlist = message.MessageData.UserList;//服务端数组
							let {users} = that.state;//本地数组
							let tempusers = [];//临时数组
							
							userlist.forEach((it)=>{
							    console.log("userList==>>",it);
							    let user = users.filter(u=>u.UserId === it.UserId);
							    console.log("users.filter==>>",JSON.stringify(user)==='[]',user);
							    if(JSON.stringify(user)!=='[]'){//存在则更新至本地数组
							        let ul = user[0];
							        ul.UserName = it.UserName;
							        ul.UserAvatar = it.UserAvatar;
							        ul.StreamId = it.StreamId;
									ul.StreamMode = it.StreamMode;
							        ul.AudioEnabled = it.AudioEnabled;
							        ul.VideoEnabled = it.VideoEnabled;
							        tempusers.push(ul);
							        console.log("更新user==>>",user);
							    }else{//不存在则添加至临时数组
							        tempusers.push(it);
							        console.log("添加user==>>",it);
							    }
							});
							
							//更新至本地数组
							that.setState({ users: tempusers, },()=>{
							    let {users} = that.state;//本地数组
							    console.log("users==>>",users);
							});
                        }
                        break;
                    case 2://离开会议
						if ((loginInfo.userid?loginInfo.userid: loginInfo.uid) !== message.SenderId){
							that.showMessage(message.SenderName + '离开会议');
							
							let userlist = message.MessageData.UserList;//服务端数组
							let {users} = that.state;//本地数组
							let tempusers = [];//临时数组
							
							userlist.forEach((it)=>{
							    console.log("userList==>>",it);
							    let user = users.filter(u=>u.UserId === it.UserId);
							    console.log("users.filter==>>",JSON.stringify(user)==='[]',user);
							    if(JSON.stringify(user)!=='[]'){//存在则更新至本地数组
							        let ul = user[0];
							        ul.UserName = it.UserName;
							        ul.UserAvatar = it.UserAvatar;
							        ul.StreamId = it.StreamId;
									ul.StreamMode = it.StreamMode;
							        ul.AudioEnabled = it.AudioEnabled;
							        ul.VideoEnabled = it.VideoEnabled;
							        tempusers.push(ul);
							        console.log("更新user==>>",user);
							    }else{//不存在则添加至临时数组
							        tempusers.push(it);
							        console.log("添加user==>>",it);
							    }
							});
							
							//更新至本地数组
							that.setState({ users: tempusers, },()=>{
							    let {users} = that.state;//本地数组
							    console.log("users==>>",users);
							});
                        }
                        break;
                    case 3://会议消息
                        let messages = this.state.messages;
                        let uid = 0;
                        if ((loginInfo.userid?loginInfo.userid: loginInfo.uid) != message.SenderId) {
                            uid = 1;
                            that.startPlay();
							that.notify("会议消息",`您收到来自【${message.SenderName}】发送的新消息`,"https://meeting.seschip.com");
                        }
                        messages.push(new Message({ id: uid, message: message.MessageData.MsgData, senderName: message.SenderName,senderAvatar:message.SenderAvatar, timesTamp: message.ExecDateTime }));
                        that.setState({
                            messages
                        });
                        break;
                    case 4://会议用户
                        let userlist = message.MessageData.UserList;//服务端数组
                        let {users} = that.state;//本地数组
                        let tempusers = [];//临时数组

                        userlist.forEach((it)=>{
                            console.log("userList==>>",it);
                            let user = users.filter(u=>u.UserId === it.UserId);
                            console.log("users.filter==>>",JSON.stringify(user)==='[]',user);
                            if(JSON.stringify(user)!=='[]'){//存在则更新至本地数组
                                let ul = user[0];
                                ul.UserName = it.UserName;
                                ul.UserAvatar = it.UserAvatar;
                                ul.StreamId = it.StreamId;
								ul.StreamMode = it.StreamMode;
                                ul.AudioEnabled = it.AudioEnabled;
                                ul.VideoEnabled = it.VideoEnabled;
                                tempusers.push(ul);
                                console.log("更新user==>>",user);
                            }else{//不存在则添加至临时数组
                                tempusers.push(it);
                                console.log("添加user==>>",it);
                            }
                        });

                        //更新至本地数组
                        that.setState({ users: tempusers, },()=>{
                            let {users} = that.state;//本地数组
                            console.log("users==>>",users);
                        });
                        break;
                }
            }
        };

        socket.onerror = (e) => {
            console.log("WebSocket发生错误.onerror==>>",e);
            socket.close();
        }

        socket.onclose = (e) => {
            if(that.state.autoreconn){
                retryCount++;
                that.setState({retryCount});
                console.log("WebSocket连接关闭.onclose()==>>",e);
                setTimeout(function() {
                    that.onReWsConnect();
                }, that.getWaitTime(retryCount));
            }else
                console.log("已自动取消自动重连操作！！！");
        }
    };

    getWaitTime = (retryCount) => {  
        const WAIT_TIME = [1000, 2000, 3000, 5000, 10000];
        if (retryCount < WAIT_TIME.length) {
            return WAIT_TIME[retryCount];
        } else {
            return WAIT_TIME[WAIT_TIME.length - 1];
        }
     };

     //判断是不是手机端
     isMobileTerminal = () => {
        console.log("判断:", window.navigator.userAgent);//window.navigator.userAgent.toLowerCase()
        let flag = window.navigator.userAgent.match(/(Mac OS X|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag?true:false;
    };

    onPublishLocalStream = async () =>{
        let that = this;
        let { localAudioEnabled,localVideoEnabled } = that.state;
        that.meeting.publishLocalStream((res)=>{
            console.log("publishLocalStream==>>",res);
			that.setState({
				StreamMode: "Stream"
			});
            that.ModifyUserStreamId(res,localAudioEnabled,localVideoEnabled);
            that.meeting.muteMediaTrack("audio", localAudioEnabled, null);
            that.meeting.muteMediaTrack("video", localVideoEnabled, null);
        });
    };

    onStartPlayStream=(userid)=>{
        let that = this;
        console.log("onStartPlayStream==>>", userid);
        that.meeting.startStreamPlay(userid);
    };

    onStopPlayStream=(userid)=>{
        let that = this;
        console.log("onStopPlayStream==>>", userid);
        that.meeting.stopStreamPlay(userid);
    };

    onSendMessage = (msg) => {
        let { loginInfo } = this.state;
        let message = {
            'MsgDataType': 3,//会议即时消息
            'MsgSendType':1,//会议消息
            'SenderId':loginInfo.userid?loginInfo.userid:loginInfo.uid,//加入房间者Id
            'SenderName':loginInfo.displayName,//加入房间者名称
            'MessageData': {
                RoomId: loginInfo.roomId,
                MsgData:msg
            }
        };
        this.send(message);
    }

    ModifyUserStreamId=(msg,AudioEnabled,VideoEnabled)=>{
        let { loginInfo,StreamMode } = this.state;
        let message = {
            'MsgDataType': 1,//会议即时消息
            'MsgSendType':1,//会议消息
            'SenderId':loginInfo.userid?loginInfo.userid:loginInfo.uid,//加入房间者Id
            'SenderName':loginInfo.displayName,//加入房间者名称
            'MessageData': {
                RoomId: loginInfo.roomId,
				StreamMode,
                AudioEnabled,
                VideoEnabled,
                StreamId:msg?msg:''
            }
        };
        console.log("ModifyUserStreamId==>>",message);
        this.send(message);
    }

    onSystemMessage = (msg) => {
        let messages = this.state.messages;
        let uid = 2;
        messages.push(new Message({ id: uid, message: msg, senderName: 'System' }));
        this.setState({ messages });
    }

    showMessage = (message, description) => {
        notification.info({
            message: message,
            description: description,
            placement: 'topRight',
        });
    }

    send = (data) => {
        let { socket } = this.state;
        console.log("socket.send==>>",data);
        if(socket && socket.readyState === WebSocket.OPEN)
            socket.send(JSON.stringify(data));
    }

    //that.meeting.muteMediaTrack("audio", localAudioEnabled, null);
    //that.meeting.muteMediaTrack("video", localVideoEnabled, null);
    handleAudioTrackEnabled = enabled => {
        let that = this;
        that.setState({localAudioEnabled:enabled},()=>{
            let { localAudioEnabled,localVideoEnabled } = that.state;
            console.log("handleAudioTrackEnabled==>>",enabled,that.state);
            that.meeting.muteMediaTrack("audio",localAudioEnabled,(res) => {
                console.log("muteMediaTrack==>>",res,that.state);
                that.ModifyUserStreamId(res,localAudioEnabled,localVideoEnabled);
            });
        });
    }

    handleVideoTrackEnabled = enabled => {
        let that = this;
        that.setState({localVideoEnabled:enabled},() => {
            let { localAudioEnabled,localVideoEnabled } = that.state;
            console.log("handleVideoTrackEnabled==>>",enabled,that.state);
            that.meeting.muteMediaTrack("video", localVideoEnabled,(res) => {
                that.ModifyUserStreamId(res,localAudioEnabled,localVideoEnabled);
            });
        });
    }

    startPlay = async () =>{
        let audio_news = document.getElementById('audio_news');
        audio_news.currentTime = 0; 
        audio_news.play();
    }

    handleScreenSharing = enabled => {
        let that = this;
		let {loginInfo,users,screenSharingEnabled} = that.state;//本地数组
		let user = users.filter(u=>u.StreamMode === "Screen");
		console.log("handleScreenSharing==>>",user);
		if (JSON.stringify(user)!=='[]' && (loginInfo.userid?loginInfo.userid: loginInfo.uid) !== user[0].UserId)
			notification.warn({ message: '屏幕共享失败，原因是【' + user[0].UserName + '】正在进行屏幕共享！', placement: 'topRight' });
		else{
			if(enabled){
				that.meeting.handleScreenSharing((res)=>{
					console.log("handleScreenSharing==>>",res);
					if(res.handletype==="screen" && res.handlestate==="success"){
						that.setState({ StreamMode: "Screen",screenSharingEnabled: res.stream?true:false },()=>{
							if(res.stream)
								that.ModifyUserStreamId(res.stream,true,true);
							else
								that.onPublishLocalStream();
						});
					}
				});
			}
		}
    }

    openOrCloseLeftContainer = collapsed => {
        this.setState({
            collapsed: collapsed
        });
    }

    cleanUp = async () => {
        await this.meeting.cleanUp();
    }

    hangUp = async () => {
        let that = this;
        confirm({
            title: "退出？",
            content: "是否确定要退出当前会议吗?",
            okText: "确定",
            cancelText: "取消",
            async onOk() {
                await that.cleanUp();
                if(that.state.loginInfo.userid) {
                    if((/MicroMessenger/i).test(window.navigator.userAgent.toLowerCase())) {
                        wx.closeWindow();
                    }else{
                        window.opener = null;
                        window.open("about:blank", "_top").close();
                    }
                }else
                    that.setState({ login: false });
            },
            onCancel() {

            }
        });
    }

    onVideodFitClickHandler = () => {
        this.setState({
            vidFit: !this.state.vidFit,
        });
    }

    onFullScreenClickHandler = () => {
        const {docElm} = this.state;
        console.log(docElm);
        if (this.fullscreenState()) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }

            this.setState({ isFullScreen: false });
        } else {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            }
            //FireFox
            else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            }
            //Chrome等
            else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            }
            //IE11
            else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }

            this.setState({ isFullScreen: true });
        }

        console.log("fullscreenState()==>>",this.fullscreenState());
    }

    fullscreenState = () => {
        return document.fullscreenElement || 
        document.mozFullScreen || 
        document.webkitIsFullScreen || 
        document.mozFullScreen || false;
    }

    onMediaSettingsChanged = (selectedAudioDevice, selectedVideoDevice, resolution, bandwidth, codec) => {
        this.settings = { selectedAudioDevice, selectedVideoDevice, resolution, bandwidth, codec };
        reactLocalStorage.setObject("settings", this.settings);
    }

    onChangeVideoPosition = (data) => {
        let that = this;
        console.log("onChangeVideoPosition",data);
        that.setState({ bigStreamUserId:data.id },() => {
            let id = that.state.bigStreamUserId;
            //let index = data.key;
            console.log("onChangeVideoPosition id:" + id);
            if (id == 0) {
                return;
            }

            const {users} = that.state;
            let first = 0;
            let big = 0;
            for (let i = 0; i < users.length; i++) {
                let item = users[i];
                if (item.UserId === id) {
                    big = i;
                    break;
                }
            }

            let c = users[first];
            users[first] = users[big];
            users[big] = c;

            this.setState({users});
        });
    };

    onMouseOverHandleClick=()=>{
        console.log("鼠标进入事件触发中……");
        this.setState({ bottomtools: true });
    };

    onMouseOutHandleClick=()=>{
        console.log("鼠标离开事件触发中……");
        this.setState({ bottomtools: false },()=>{
            this.openOrCloseLeftContainer(true);
        });
    };

    onHandleClick=()=>{
        console.log("鼠标点击事件触发中……");
        this.setState({ bottomtools: !this.state.bottomtools },()=>{
            if(!this.state.bottomtools)
                this.openOrCloseLeftContainer(!this.state.bottomtools);
        });
    };

    render() {
        const {
            login,
            loading,
            loginInfo,
            users,
            messages,
            publisherUrl,
            localAudioEnabled,
            localVideoEnabled,
            screenSharingEnabled,
            collapsed,
            bottomtools,
            vidFit,
            isFullScreen,
            isMobileTerminal,
            bigStreamUserId
        } = this.state;
        watermark.init({"watermark_txt":login?"知码芯-"+loginInfo.displayName:'知码芯-音视频会议系统','watermark_width':280,'watermark_angle':45});
        return (
            <Layout className="app-layout">
                <audio id="audio_news" controls="controls" hidden src="/audio/news.mp3" ref="audio"></audio>
                {login ? (
                    <Content className="app-center-layout" onMouseEnter={this.onMouseOverHandleClick} onMouseLeave={this.onMouseOutHandleClick}>
                        <Layout className="app-content-layout">
                            <Sider
                                width={320}
                                style={{ background: '#f5f5f5' }}
                                collapsedWidth={0}
                                trigger={null}
                                collapsed={collapsed}
                            >
                                <div className="left-container">
                                    <UserList users={users}></UserList>
                                    <ChatFeed messages={messages} onSendMessage={this.onSendMessage} />
                                </div>
                            </Sider>
                            <Layout className="app-right-layout">
                                <Content style={{ flex: 1 }}>
                                    <MeetingPage
                                        uid={loginInfo.uid}
                                        loginInfo={loginInfo}
                                        users={users}
                                        settings={this.settings}
                                        publisherUrl={publisherUrl}
                                        localAudioEnabled={localAudioEnabled}
                                        localVideoEnabled={localVideoEnabled}
                                        bigStreamUserId={bigStreamUserId}
                                        ChangeVideoPosition={this.onChangeVideoPosition}
                                        HandleClick={this.onHandleClick}
                                        Collapsed={bottomtools}
                                        vidFit={vidFit}
                                        ref={ref => {
                                            this.meeting = ref;
                                        }}
                                    />
                                    <div className="app-bottom-tools-bg" style={{display:bottomtools?"":"none"}}>
                                    </div>
                                    <div className="app-bottom-tools" style={{display:bottomtools?"":"none"}}>
                                        <Button
                                            ghost
                                            icon={collapsed ? "right" : "left"}
                                            size="large"
                                            type="link"
                                            onClick={() => this.openOrCloseLeftContainer(!collapsed)}
                                        >聊天面板</Button>

                                        <div className="app-bottom-tool">
                                            <Button
                                                size="large"
                                                type="link"
                                                ghost
                                                style={{ color: localAudioEnabled ? "" : "red" }}
                                                onClick={() => {this.setState({localAudioEnabled:!localAudioEnabled}); this.handleAudioTrackEnabled(!localAudioEnabled);}}
                                            >
                                                <Icon component={ localAudioEnabled ? MicrophoneIcon : MicrophoneOffIcon } style={{ display: "flex", justifyContent: "center" }} />
                                                禁音
                                            </Button>

                                            <Button
                                                size="large"
                                                type="link"
                                                ghost
                                                style={{ color: localVideoEnabled ? "" : "red" }}
                                                onClick={() => {this.setState({localVideoEnabled:!localVideoEnabled}); this.handleVideoTrackEnabled(!localVideoEnabled)}}
                                            >
                                                <Icon component={ localVideoEnabled ? VideoIcon : VideoOffIcon } style={{ display: "flex", justifyContent: "center" }} />
                                                视频
                                            </Button>

                                            {isMobileTerminal || (/MicroMessenger/i).test(window.navigator.userAgent.toLowerCase()) ? '' : 
                                            <Button
                                                ghost
                                                size="large"
                                                type="link"
                                                style={{ color: screenSharingEnabled ? "red" : "" }}
                                                onClick={() => this.handleScreenSharing(!screenSharingEnabled)}
                                            >
                                                <Icon component={ screenSharingEnabled ? TelevisionOffIcon : TelevisionIcon } style={{ display: "flex", justifyContent: "center" }} />
                                                共享
                                            </Button>
                                            }

                                            <Button
                                                size="large"
                                                type="link"
                                                ghost
                                                style={{ marginLeft: 16, marginRight: 16, color:"red" }}
                                                onClick={() => this.hangUp()}
                                            >
                                                <Icon component={HangupIcon} style={{ display: "flex", justifyContent: "center" }} />
                                                离开
                                            </Button>

                                            <ShareMeeting loginInfo={loginInfo} />

                                            <Button
                                                ghost
                                                size="large"
                                                type="link"
                                                onClick={() => this.onVideodFitClickHandler()}
                                            >
                                                <Icon component={ vidFit ? CheckboxBlankOutlineIcon : CheckboxIntermediateIcon } style={{ display: "flex", justifyContent: "center" }} />
                                                适合/拉伸
                                            </Button>
                                        </div>

                                        <Button
                                            ghost
                                            size="large"
                                            type="link"
                                            onClick={() => this.onFullScreenClickHandler()}
                                        >
                                            <Icon component={ isFullScreen ? FullscreenExitIcon : FullscreenIcon } style={{ display: "flex", justifyContent: "center" }} />
                                            全屏/退出
                                        </Button>
                                        <MediaSettings onMediaSettingsChanged={this.onMediaSettingsChanged} settings={this.settings} />
                                    </div>
                                </Content>
                            </Layout>
                        </Layout>
                    </Content>
                ) : loading ? (
                    <Spin size="large" tip="正在连接..." />
                ) : (
                    <LoginForm handleLogin={this.handleLogin} />
                )}
            </Layout>);
    }
}
export default App;