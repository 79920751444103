import React from "react";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import VideocamOffIcon from "mdi-react/VideocamOffIcon";
import { Avatar,Icon,Button } from 'antd';

class LocalVideoComponent extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }
    componentDidMount = () => {
        const {stream} = this.props;
        this.video.srcObject = stream;
    }
    
    componentWillUnmount = () => {
        if(this.video && this.video.srcObject)
            this.video.srcObject = null;
    }

    render = () => {
        const { id,name,avatar,audioMuted,videoMuted } = this.props;
        return (
            <div className="local-video-container" style={{borderWidth: '0.5px'}}>
                <video
                    ref={ref =>{
                        this.video = ref;
                    }}
                    id={id}
                    autoPlay
                    playsInline
                    muted={true}
                    className="local-video-size"
                />
                {
                    videoMuted ? "" : 
                    <div className='local-video-avatar'>
                        {avatar ? (<Avatar size={48} src={avatar} />) : (<Avatar size={48} icon="user"/>)}
                    </div>
                }
                <div className='local-video-icon-layout'>
                    {audioMuted ? "" : <MicrophoneOffIcon size={18} color="white"/>}
                    {videoMuted ? "" : <VideocamOffIcon size={18} color="white"/>}
                </div>

                <a className="local-video-name">{name}</a>
            </div>
        )
    }
}

export default LocalVideoComponent;